#active-commit {
    animation: blink .8s infinite alternate;
}
#active-incoming {
    animation: blink2 .8s .1s  infinite alternate;


}



@keyframes blink {
    0% {
        color: #fff;
    }

    100% {
        color: #42B0DC;
    }
}

@keyframes blink2 {
    0% {
        color: #E6E6E6;
    }

    100% {
        color: #42B0DC;
    }
}
