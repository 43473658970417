#i-shape-losange {
    transform-origin: center;
    transform: translate(1000px, 100px);
    animation: stroke_fill 10s linear forwards;
    stroke-dasharray: 1000;
    stroke-dashoffset: 1000; 
    stroke: #42B0DC;
    stroke-width: 1;
    transition: all 0.3s ease-in-out;
}

.circle {
    animation: rotating 5s infinite linear;
    transform-box: fill-box;
    transform-origin: center;
}

#circle-1 {
    animation: stroke_fill 10s linear forwards;
    stroke-dasharray: 1000;
    stroke-dashoffset: 1000; 
    stroke: #42B0DC;
    stroke-width: 1;
    transition: all 0.3s ease-in-out;

  }
.circle1{
    pointer-events: all;

}



#circle-2 {
    animation: circle2 0.8s  infinite alternate;
    transform-box: fill-box;
    transform-origin: bottom;
  }
#circle-3 {
    transform: rotate(30deg);
    transform: translate(15px, 30px);
    animation: stroke_fill 10s linear forwards;
    stroke-dasharray: 1000;
    stroke-dashoffset: 1000; 
    stroke: #42B0DC;
    stroke-width: 1;
    transition: all 0.3s ease-in-out;
  }
#circle-4 {

    // animation: rotating 5s infinite linear;
    @media (min-width: 1800px) {
        transform: translate(500px, 450px);
      }

  }




@keyframes circle1 {
from {
    transform: translateY(0%);
}
to {
    transform: translateY(-20%);
}
}
@keyframes circle2 {
from {
    transform: translateY(0%);
}
to {
    transform: translateY(-30%);
}
}
@keyframes circle3 {
from {
    transform: translateY(0%);
}
to {
    transform: translateY(-30%);
}
}
@keyframes circle4 {
from {
    transform: translateY(0%);
}
to {
    transform: translateY(-30%);
}
}

@keyframes rotating {

        from {
          -ms-transform: rotate(0deg);
          -moz-transform: rotate(0deg);
          -webkit-transform: rotate(0deg);
          -o-transform: rotate(0deg);
          transform: rotate(0deg);
        }
        to {
          -ms-transform: rotate(360deg);
          -moz-transform: rotate(360deg);
          -webkit-transform: rotate(360deg);
          -o-transform: rotate(360deg);
          transform: rotate(360deg);
        }
}


@keyframes draw {
    to {
      stroke-dashoffset: 0;
    }
  }


  @keyframes stroke_fill {
    0% {
        fill: white;
    }
    20% {
        fill: white;
        stroke-dashoffset: 800;
    }
    40% {
        fill: url(#illustration-01);
        stroke-dashoffset: 400;
    }
    80% {
        fill: url(#illustration-01);
        stroke-dashoffset: 200;
    }
    100% {
        fill: url(#illustration-01);
        stroke-dashoffset: 0;
    }
}